import { Configuration } from "./configuration"

const BuildConfigurations = (configs: any) => {
    Configuration.Environment = configs.env
    Configuration.NetworkId = configs.networkId
    Configuration.PartnerId = configs.partnerId
    Configuration.PlatformUrl = configs.platformUrl
    Configuration.BasePath = configs.basePath
    Configuration.TableManagerUrl = configs.tableManagerUrl
    Configuration.TournamentManagerUrl = configs.tournamentManagerUrl
    Configuration.NotificationServiceUrl = configs.notificationServiceUrl
    Configuration.ClientUrl = `${configs.clientProtocol}://${configs.clientDomain}:${configs.clientPort}/`
    Configuration.ClientAssetUrl = `${configs.clientProtocol}://${configs.clientDomain}:${configs.clientPort}/${configs.assets}`
    Configuration.ClientAssetCommonUrl = `${Configuration.ClientAssetUrl}${configs.config.commonAssets}`
    Configuration.ClientAssetHomePageUrl = `${Configuration.ClientAssetUrl}${configs.config.homePageAssets}`
    Configuration.ClientAssetMyAccountsPageUrl = `${Configuration.ClientAssetUrl}${configs.config.myaccountsAssets}`
    Configuration.ClientAssetPromotionsUrl = `${Configuration.ClientAssetUrl}${configs.config.promotionsAssets}`
    Configuration.ClientAssetBankingUrl = `${Configuration.ClientAssetUrl}${configs.config.bankingAssets}`
    Configuration.LobbyUrl = `${Configuration.ClientUrl}${configs.lobbyUrl}`
    Configuration.GameRoomTabType = configs.config.GameRoomTabType
    Configuration.DefaultCashType = configs.config.defaultCashType
    Configuration.DefaultMainMenu = configs.config.defaultMainMenu
    Configuration.DefaultLanguage = configs.config.defaultLanguage
    Configuration.GoogleClientId = configs.googleClientId
    Configuration.GoogleApiKey = configs.googleAPIKey
    Configuration.FacebookClientId = configs.facebookClientId
    Configuration.DeviceInfoRequired = configs.config.isDeviceInfoRequired
    Configuration.DeviceCredentials = { username: configs.appDeviceUsername ? configs.appDeviceUsername : '', password: configs.appDevicePassword ? configs.appDevicePassword : '' }
    Configuration.ExpiryBufferMinutes = configs.config.expiryBufferMinutes
    Configuration.DeviceExpiryTokenInMins = configs.config.deviceExpiryTokenInMins
    Configuration.AccessExpiryTokenInMins = configs.config.accessExpiryTokenInMins
    Configuration.Languages = configs.languages
    Configuration.MyaccountsMenus = configs.myaccountsMenus
    Configuration.HowToPlayMenus = configs.howToPlayMenus
    Configuration.AboutUsFaqTermsMenus = configs.aboutusFaqMenus
    Configuration.ClubDetails = configs.clubDetails
    Configuration.KycOptions = configs.kyc
    Configuration.KycDocumentTypeOptions = configs.kycDocumentTypes
    Configuration.HistoryFieldTypes = configs.historyFieldTypes
    Configuration.PromotionsCategory = configs.promotions.category
    Configuration.WebsiteMainMenu = configs.websiteMainMenu
    Configuration.BankingMenus = configs.bankingMenus
    Configuration.PlayerDefaultAmounts = configs.playerDefaultAmounts
    Configuration.DepositBonus = configs.depositBonus
    Configuration.InvalidPromotionTimeInSeconds = configs.config.invalidPromotionTimeInSeconds
    Configuration.DepositPaymentTypes = configs.depositPaymentTypes
    Configuration.PaymentDepositRedirectionUrl = configs.depositPaymentRedirectionUrl
    Configuration.WithdrawOTPTimeInSeconds = configs.config.withdrawOTPTimeInSeconds
    Configuration.InternalSuccessMessageTimeInSeconds = configs.config.internalSuccessMessageTimeInSeconds
    Configuration.WithdrawPaymentTypes = configs.withdrawPaymentTypes
    Configuration.BankAccountTypes = configs.bankAccountTypes
    Configuration.WithdrawPaymentWallets = configs.withdrawPaymentWallets       //configs.config.isUpiEnabled ? : []
    Configuration.SuccessMessageTimeInSeconds = configs.config.successMessageTimeInSeconds
    Configuration.FailureMessageTimeInSeconds = configs.config.failureMessageTimeInSeconds
    Configuration.SideMenus = configs.sideMenuItems
    Configuration.footerMenus = configs.footerMenus
    Configuration.quickMenus = configs.quickMenus
    Configuration.downloadMenu = configs.downloadMenu
    Configuration.apiError = configs.apiError
    Configuration.DepositRetryTimerInSeconds = configs.config.depositRetryTimerInSeconds
    Configuration.WithdrawRetryTimerInSeconds = configs.config.withdrawRetryTimerInSeconds
    Configuration.WithdrawWaitingTimeInSeconds = configs.config.withdrawWaitingTimeInSeconds
    Configuration.DataTableColumns = configs.dataTableColumns
    Configuration.RefreshTokenIntervalInSeconds = configs.config.refreshTokenIntervalInSeconds
    Configuration.AccountHistoryDepositColumns = configs.accountHistoryColumns
    Configuration.AccountHistoryLimits = configs.config.accountHistoryLimit
    Configuration.AccountHistoryReconcileChipTypes = configs.accountHistoryReconcileChipType
    Configuration.VerifyMobileRetryTimerInSeconds = configs.config.verifyMobileRetryTimerInSeconds
    Configuration.PlayerLogsMenus = configs.playerLogsMenus
    Configuration.GameTypes = configs.gameTypes
    Configuration.PlayerLogLimits = configs.config.playerLogLimits
    Configuration.PlayerGameLogLimits = configs.config.playerGameLogLimits
    Configuration.PlayerLogAvailableLimits = configs.PlayerLogAvailableLimits
    Configuration.PlayerLogsHistoryColumns = configs.playerLogsHistoryColumns
    Configuration.ReferNEarnMenus = configs.referNEarnMenus
    Configuration.ReferNEarnContactsMenus = configs.referNEarnContactsMenus
    Configuration.ReferNEarnContactsSuccessMenus = configs.referNEarnContactsSuccessMenus
    Configuration.ReferChannelConfigs = configs.referChannelsWindowConfig
    Configuration.LoggerUrl = configs.loggerServiceUrl
    Configuration.LogLevels = configs.loggingLevel
    Configuration.RemoteLogEnabled = configs.remoteLoggingEnabled === 'true' ? true : false
    Configuration.LogDisabled = configs.loggingDisabled === 'true' ? true : false
    Configuration.ReduxLogDisabled = configs.reduxLoggingDisabled === 'true' ? true : false
    //Configuration.IsUpiEnabled = configs.config.isUpiEnabled
}

const ConfigGenerator = {
    BuildConfigurations: BuildConfigurations
}

export default ConfigGenerator