
export class Configuration {
    public static Environment;
    public static NetworkId
    public static PartnerId;
    public static PlatformUrl;
    public static BasePath;
    public static TableManagerUrl;
    public static TournamentManagerUrl;
    public static NotificationServiceUrl;
    public static ClientUrl;
    public static ClientAssetUrl;
    public static ClientAssetCommonUrl;
    public static ClientAssetHomePageUrl;
    public static ClientAssetMyAccountsPageUrl;
    public static ClientAssetPromotionsUrl;
    public static ClientAssetBankingUrl;
    public static LobbyUrl;
    public static GameRoomTabType;
    public static DefaultCashType;
    public static DefaultMainMenu;
    public static DefaultLanguage;
    public static GoogleClientId;
    public static GoogleApiKey;
    public static FacebookClientId;
    public static DeviceInfoRequired;
    public static DeviceCredentials;
    public static ExpiryBufferMinutes;
    public static DeviceExpiryTokenInMins;
    public static AccessExpiryTokenInMins;
    public static Languages;
    public static MyaccountsMenus;
    public static AboutUsFaqTermsMenus;
    public static ClubDetails;
    public static KycOptions;
    public static KycDocumentTypeOptions;
    public static HistoryFieldTypes;
    public static PromotionsCategory;
    public static WebsiteMainMenu;
    public static BankingMenus;
    public static PlayerDefaultAmounts;
    public static DepositBonus;
    public static InvalidPromotionTimeInSeconds;
    public static DepositPaymentTypes;
    public static PaymentDepositRedirectionUrl;
    public static WithdrawOTPTimeInSeconds;
    public static InternalSuccessMessageTimeInSeconds;
    public static WithdrawPaymentTypes;
    public static BankAccountTypes;
    public static WithdrawPaymentWallets;
    public static SuccessMessageTimeInSeconds;
    public static FailureMessageTimeInSeconds;
    public static SideMenus;
    public static footerMenus;
    public static quickMenus;
    public static downloadMenu;
    public static apiError;
    public static DepositRetryTimerInSeconds;
    public static WithdrawRetryTimerInSeconds;
    public static WithdrawWaitingTimeInSeconds;
    public static DataTableColumns;
    public static RefreshTokenIntervalInSeconds;
    public static AccountHistoryDepositColumns;
    public static AccountHistoryLimits;
    public static AccountHistoryReconcileChipTypes;
    public static VerifyMobileRetryTimerInSeconds;
    public static PlayerLogsMenus;
    public static GameTypes;
    public static PlayerLogLimits;
    public static PlayerGameLogLimits;
    public static PlayerLogAvailableLimits;
    public static PlayerLogsHistoryColumns;
    public static ReferNEarnMenus;
    public static ReferNEarnContactsMenus;
    public static ReferNEarnContactsSuccessMenus;
    public static ReferChannelConfigs;
    public static LoggerUrl;
    public static LogLevels;
    public static RemoteLogEnabled;
    public static LogDisabled;
    public static ReduxLogDisabled;
    public static HowToPlayMenus;

    //public static IsUpiEnabled;    
}